import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import { StaticImage } from 'gatsby-plugin-image'

import Scrollspy from 'react-scrollspy'
import Scroll from '../../components/Scroll'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'
import Nav from '../../components/Nav'

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import apple_budge from '../../assets/images/App_Store_Badge_US-UK_135x40.png'
import utsushiyo01 from '../../assets/images/utsushiyo/utsushiyo_sc1.jpg'
import utsushiyo02 from '../../assets/images/utsushiyo/utsushiyo_sc2.jpg'
import utsushiyo03 from '../../assets/images/utsushiyo/utsushiyo_sc3.jpg'
import utsushiyo04 from '../../assets/images/utsushiyo/utsushiyo_sc4.jpg'

const Utsushiyo = ({ data, location }) => {
  const [stickyNav, setStickyNav] = useState(false)
  const handleWaypointEnter = () => {
    setStickyNav(false)
  }
  const handleWaypointLeave = () => {
    setStickyNav(true)
  }

  const utsushiyo_main = '../../assets/images/utsushiyo/utsushiyo_main.jpg'

  return (
    <Layout>
      <Helmet title="脱出ゲーム うつしよ" />
      <HeaderGeneric />
      <Waypoint
        onEnter={handleWaypointEnter}
        onLeave={handleWaypointLeave}
      ></Waypoint>
      <Nav sticky={stickyNav} />

      <div id="main">
        <section id="top" className="main special">
          <header className="major">
              <h2>脱出ゲーム うつしよ</h2>
          </header>
          <span className="image major aligncenter"><StaticImage src={utsushiyo_main} alt="" width="480" /></span>
        </section>
        <section id="index" className="main">
          <Scrollspy items={ ['download', 'movie', 'content', 'sc'] } currentClassName="is-active" offset={-300}></Scrollspy>
          <ul>
            <li>
              <Scroll type="id" element="download">
                  <a href="#download">ダウンロード</a>
              </Scroll>
            </li>
            <li>
              <Scroll type="id" element="movie">
                <a href="#movie">ムービー</a>
              </Scroll>
            </li>
            <li>
              <Scroll type="id" element="content">
                <a href="#content">内容</a>
              </Scroll>
            </li>
            <li>
              <Scroll type="id" element="sc">
                <a href="#sc">スクリーンショット</a>
              </Scroll>
            </li>
          </ul>
        </section>
        <section id="download" className="main">
          <header className="major">
            <h2>ダウンロード</h2>
          </header>
          <div className="horizon-budges">
            <a href="https://itunes.apple.com/jp/app/id1143885008?mt=8" target="_blank" rel="noopener noreferrer" className="icon"><img className="apple" src={apple_budge} alt="" /></a>
            <a href="https://play.google.com/store/apps/details?id=net.cretia.escapeutsushiyo" target="_blank" rel="noopener noreferrer" className="icon"><img className="google" src="https://play.google.com/intl/ja/badges/images/generic/en_badge_web_generic.png" alt="Get it on Google Play" width="155" height="60" /></a>
          </div>
        </section>
        <section id="movie" className="main">
          <header className="major">
            <h2>ムービー</h2>
          </header>
          <div className="video-player">
            <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/hRWug-pJGi8?rel=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen="allowfullscreen"></iframe>
          </div>
        </section>
        <section id="content" className="main">
          <header className="major">
            <h2>内容</h2>
          </header>
          <h3>それは、ちょっぴり怖くて不思議な世界の物語――</h3>
          <p>「脱出×ホラー×本格シナリオ」で織りなす ”うつしよ” の世界へようこそ。</p>
          <p>二人の少女が迷い込んだ世界。そこは逃げ場のない紅い世界――</p>
          <p>この世界は過去？　未来？　それとも……？<br />
          脱出の先にあるこの世界と二人の少女の真実を、あなたの手で解き明かそう。</p>

          <h3>行く手を阻む数々の謎と仕掛け</h3>
          <p>プレイヤー自身の手で仕掛けを動かして謎を解く、本格脱出ゲーム。</p>
          <p>「調べる」「アイテムの使用、組み合わせ」はもちろん、「回転」「移動」で解く仕掛けもあり！<br />
          手がかりを集め、”ひらめき”と”論理”を駆使して脱出しよう！<br />
          ヒントもあるので、初心者も安心♪</p>

          <hr />

          <table>
            <colgroup><col span="1" /></colgroup>
            <tbody>
              <tr><th>タイトル</th><td>脱出ゲーム うつしよ</td></tr>
              <tr><th>プラットフォーム</th><td>iOS 10.0 以上 / Android 4.4 以上</td></tr>
              <tr><th>ジャンル</th><td>脱出×ノベルゲーム</td></tr>
              <tr><th>提供形態</th><td>無料ゲーム(広告、課金コンテンツあり)</td></tr>
              <tr><th>リリース日</th><td>2016年 9月 27日</td></tr>
            </tbody>
          </table>
        </section>
        <section id="sc" className="main">
          <header className="major">
            <h2>スクリーンショット</h2>
          </header>
          <Carousel className="carousel-custom" width={340} showArrows={true} showThumbs={true} showIndicators={false}>
              <div>
                  <img src={utsushiyo01} alt="" />
              </div>
              <div>
                  <img src={utsushiyo02} alt="" />
              </div>
              <div>
                  <img src={utsushiyo03} alt="" />
              </div>
              <div>
                  <img src={utsushiyo04} alt="" />
              </div>
          </Carousel>
        </section>
      </div>
    </Layout>
  )
}

export default Utsushiyo
